// import * as bootstrap from 'bootstrap';
import Collapse from 'bootstrap/js/dist/collapse';
import Modal from 'bootstrap/js/dist/modal';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Tooltip from 'bootstrap/js/dist/tooltip';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
    new Tooltip(tooltipTriggerEl);
    tooltipTriggerEl.addEventListener('contextmenu', event => event.preventDefault());
});